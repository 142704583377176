@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");

.matching-headline {
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  text-align: center;
}

.match-container {
  width: 96% !important;
  max-width: 500px;
  min-height: 60vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.vibe-percentage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.error-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.loading-scanner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-scanner-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loading-scanner-text-letter {
  font-size: 2rem;
  font-family: "Press Start 2P", cursive;
}

@media (max-width: 768px) {
  .matching-signout {
    display: none;
  }
}
