.leaderboard-container {
  min-height: 100vh;
  /* background: linear-gradient(135deg, #e66465, #9198e5); */
  background-color: #9e2146;
  padding: 20px;
  padding-bottom: 0px;
  font-family: "Press Start 2P", cursive; /* Use a pixel art font */
}
.top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
  height: fit-content;
  flex-direction: row-reverse;
}
.crazy-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
      circle at 50% 50%,
      rgba(255, 0, 255, 0.5),
      transparent 50%
    ),
    radial-gradient(circle at 100% 0, rgba(0, 255, 255, 0.5), transparent 50%),
    radial-gradient(circle at 0 100%, rgba(255, 255, 0, 0.5), transparent 50%),
    radial-gradient(circle at 0 0, rgba(255, 0, 0, 0.5), transparent 50%),
    radial-gradient(circle at 100% 100%, rgba(0, 255, 0, 0.5), transparent 50%);
  background-size: 200% 200%;
  animation: crazyShift 10s ease infinite;
  z-index: 0;
  pointer-events: none;
}

.crazy-background::before,
.crazy-background::after {
  content: "";
  position: absolute;
  width: 200vmax;
  height: 200vmax;
  top: -100vmax;
  left: -100vmax;
  animation: rotate 20s linear infinite;
  background: rgba(255, 255, 255, 0.1);
  /* background-image: repeating-conic-gradient(
    transparent 0deg 15deg,
    rgba(255, 255, 255, 0.1) 15deg 30deg
  ); */
}

.user-info-leaderboard {
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 20px;
  max-width: calc(100% - 40px);
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-name {
  color: white;
  font-size: 14px;
}

.signout-button {
  background: #ff4757;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
}

.vibe-match-logo-leaderboard {
  font-size: 24px;
  color: white;
  text-shadow: 0 0 10px #ff6b6b;
  z-index: 10; /* Ensure it's above other elements */
}

.logo.pixel-art-leaderboard.logged-in {
  position: relative;
  top: 0px;
  right: 0px;
  left: auto; /* Reset left positioning */
  transform: none; /* Reset transform */
  font-size: 1.5em;
  margin: 0;
  padding: 10px 20px 10px 10px;
  text-shadow: 1px 1px 0 #ff00ff, 2px 2px 0 #00ffff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.leaderboard-title {
  text-align: center;
  font-size: 48px;
  color: #feca57;
  text-shadow: 0 0 10px #ff9ff3;
  margin-top: 40px;
  margin-bottom: 20px;
}

.leaderboard-content {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.subtitle {
  text-align: center;
  font-size: 24px;
  color: #ff9ff3;
  margin-bottom: 30px;
}

.similar-users-title {
  font-size: 20px;
  color: #feca57;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.5;
}

.similar-users-list {
  list-style-type: none;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
}

.similar-user-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #ffd700;
}

.similar-user-rank {
  font-weight: bold;
  color: #ff9ff3;
  margin-right: 10px;
}

.similar-user-email {
  flex-grow: 1;
  color: white;
}

.similar-user-score {
  font-weight: bold;
  color: #feca57;
}

.match-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.match-button {
  font-family: "Press Start 2P", cursive;
  font-size: 18px;
  padding: 20px 15px;
  border: 2px solid #ffd700;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 0 0 2px #ffd700, 0 0 10px 2px #ffd700, 0 0 20px 2px #ffd700;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  height: 80px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  background-color: #ff9ff3;
  line-height: 1.5;
}

.pixel-hearts {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.pixel-heart {
  position: relative;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.pixel-heart::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 2px;
  background-color: #f22613;
  box-shadow: 2px 0 #f22613, 4px 0 #f22613, 8px 0 #f22613, 10px 0 #f22613,
    0 2px #f22613, 2px 2px #f22613, 4px 2px #f22613, 6px 2px #f22613,
    8px 2px #f22613, 10px 2px #f22613, 12px 2px #f22613, 0 4px #f22613,
    2px 4px #f22613, 4px 4px #f22613, 6px 4px #f22613, 8px 4px #f22613,
    10px 4px #f22613, 12px 4px #f22613, 2px 6px #f22613, 4px 6px #f22613,
    6px 6px #f22613, 8px 6px #f22613, 10px 6px #f22613, 4px 8px #f22613,
    6px 8px #f22613, 8px 8px #f22613, 6px 10px #f22613;
}

.similar-user-item.top-three {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  margin-bottom: 5px;
}

.similar-user-item.top-three .similar-user-email {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.similar-user-item.top-three:nth-child(1) .pixel-heart::before {
  background-color: red;
  box-shadow: 2px 0 red, 4px 0 red, 8px 0 red, 10px 0 red, 0 2px red,
    2px 2px red, 4px 2px red, 6px 2px red, 8px 2px red, 10px 2px red,
    12px 2px red, 0 4px red, 2px 4px red, 4px 4px red, 6px 4px red, 8px 4px red,
    10px 4px red, 12px 4px red, 2px 6px red, 4px 6px red, 6px 6px red,
    8px 6px red, 10px 6px red, 4px 8px red, 6px 8px red, 8px 8px red,
    6px 10px red;
}

.similar-user-item.top-three:nth-child(2) .pixel-heart::before {
  background-color: red;
  box-shadow: 2px 0 red, 4px 0 red, 8px 0 red, 10px 0 red, 0 2px red,
    2px 2px red, 4px 2px red, 6px 2px red, 8px 2px red, 10px 2px red,
    12px 2px red, 0 4px red, 2px 4px red, 4px 4px red, 6px 4px red, 8px 4px red,
    10px 4px red, 12px 4px red, 2px 6px red, 4px 6px red, 6px 6px red,
    8px 6px red, 10px 6px red, 4px 8px red, 6px 8px red, 8px 8px red,
    6px 10px red;
}

.similar-user-item.top-three:nth-child(3) .pixel-heart::before {
  background-color: red;
  box-shadow: 2px 0 red, 4px 0 red, 8px 0 red, 10px 0 red, 0 2px red,
    2px 2px red, 4px 2px red, 6px 2px red, 8px 2px red, 10px 2px red,
    12px 2px red, 0 4px red, 2px 4px red, 4px 4px red, 6px 4px red, 8px 4px red,
    10px 4px red, 12px 4px red, 2px 6px red, 4px 6px red, 6px 6px red,
    8px 6px red, 10px 6px red, 4px 8px red, 6px 8px red, 8px 8px red,
    6px 10px red;
}

@media (max-width: 768px) {
  .top-container {
    justify-content: center;
    margin-top: 20px;
  }

  .leaderboard-container {
    padding: 10px;
  }

  .user-info-leaderboard {
    display: none;
  }

  .vibe-match-logo-leaderboard {
    position: static;
    text-align: right;
    margin-bottom: 10px;
    padding: 10px;
  }

  .leaderboard-title {
    font-size: 28px;
  }

  .leaderboard-content {
    padding: 10px;
  }

  .similar-users-title {
    font-size: 15px;
    margin: 10px 0px;
  }

  .similar-user-item {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15px;
    border: 2px solid #ffd700;
    font-size: 12px;
    gap: 5px;
  }

  .similar-user-rank {
    width: 100%;
    margin-bottom: 5px;
    font-size: 1em; /* Slightly larger than base */
    min-width: 25px; /* Ensure consistent width for alignment */
    text-align: center;
  }

  .similar-user-email {
    margin-bottom: 5px;
    font-size: 1em; /* Same as base size */
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    word-wrap: break-word;
    white-space: normal;
    text-align: center;
  }

  .similar-user-score {
    width: 100%;
    font-size: 0.9em; /* Slightly smaller than base */
    text-align: center;
  }
  .match-button {
    font-size: 14px;
    padding: 15px 10px;
    height: auto;
  }

  .leaderboard-container {
    display: flex;
    flex-direction: column;
  }
}
